/*
 *  Document: datatables.less
 *
 *  Datatables
 *
 */

.dataTables_wrapper > div {
    background-color: @color_light_ultra;
    padding: 8px 0 5px;
    width: auto;
    border: 1px solid @color_light_main;
    border-top-width: 0;
}

.dataTables_wrapper > div:first-child {
    border-top-width: 1px;
    border-bottom-width: 0;
}

.dataTables_wrapper .row {
    margin: 0;
}

.dataTables_filter label,
.dataTables_length label,
.dataTables_info,
.dataTables_paginate {
    margin: 0;
    padding: 0;
}

.dataTables_filter label {
    font-weight: normal;
    float: left;
    text-align: left;
}

div.dataTables_length select {
    width: 75px;
}

div.dataTables_filter label {
    font-weight: normal;
    float: right;
}

div.dataTables_filter input {
    width: 150px;
}

.dataTables_info {
    padding-top: 8px;
}

.dataTables_paginate {
    float: right;
    margin: 0;
}

div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
}

table.dataTable td,
table.dataTable th {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

table.dataTable {
    clear: both;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    max-width: none !important;
}

table.table thead .sorting,
table.table thead .sorting_asc,
table.table thead .sorting_desc,
table.table thead .sorting_asc_disabled,
table.table thead .sorting_desc_disabled {
    cursor: pointer;
    *cursor: hand;
    padding-right: 20px;
}

.table thead .sorting,
.table thead .sorting_asc,
.table thead .sorting_desc,
.table thead .sorting_asc_disabled,
.table thead .sorting_desc_disabled {
    background-position: center right;
    background-repeat: no-repeat;
    background-size: 19px 19px;
}

.table thead .sorting { background-image: url('@{assets_folder}/jquery.datatables/sort_both.png'); }
.table thead .sorting_asc { background-image: url('@{assets_folder}/jquery.datatables/sort_asc.png'); }
.table thead .sorting_desc { background-image: url('@{assets_folder}/jquery.datatables/sort_desc.png'); }
.table thead .sorting_asc_disabled { background-image: url('@{assets_folder}/jquery.datatables/sort_asc_disabled.png'); }
.table thead .sorting_desc_disabled { background-image: url('@{assets_folder}/jquery.datatables/sort_desc_disabled.png'); }

table.dataTable thead > tr > th {
    padding-left: 18px;
    padding-right: 18px;
}

table.dataTable th:active {
    outline: none;
}

div.dataTables_scrollHead table {
    margin-bottom: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

div.dataTables_scrollHead table thead tr:last-child th:first-child,
div.dataTables_scrollHead table thead tr:last-child td:first-child {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

div.dataTables_scrollBody table {
    border-top: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

div.dataTables_scrollBody tbody tr:first-child th,
div.dataTables_scrollBody tbody tr:first-child td {
    border-top: none;
}

div.dataTables_scrollFoot table {
    margin-top: 0 !important;
    border-top: none;
}

div.dataTables_scrollHead table.table-bordered {
    border-bottom-width: 0;
}

@media only screen and (-Webkit-min-device-pixel-ratio: 1.5),
only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-device-pixel-ratio: 1.5) {

    .table thead .sorting { background-image: url('@{assets_folder}/jquery.datatables/sort_both@2x.png'); }
    .table thead .sorting_asc { background-image: url('@{assets_folder}/jquery.datatables/sort_asc@2x.png'); }
    .table thead .sorting_desc { background-image: url('@{assets_folder}/jquery.datatables/sort_desc@2x.png'); }
    .table thead .sorting_asc_disabled { background-image: url('@{assets_folder}/jquery.datatables/sort_asc_disabled@2x.png'); }
    .table thead .sorting_desc_disabled { background-image: url('@{assets_folder}/jquery.datatables/sort_desc_disabled@2x.png'); }
}