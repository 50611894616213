/*
 *  Document: easy_pie_chart.less
 *
 *  Easy Pie Chart
 *
 */

.easyPieChart {
    position: relative;
    text-align: center;
    margin: 0 auto;
    font-size: 24px;
    font-weight: 300;
}

.easyPieChart small {
    font-size: 14px;
}

.easyPieChart canvas {
    position: absolute;
    top: 0;
    left: 0;
}