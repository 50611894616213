/*
 *  Document: prism.less
 *
 *  prism.js okaidia theme for JavaScript, CSS and HTML
 *
 *  Loosely based on Monokai textmate theme by http://www.monokai.nl/
 *  @author ocodia
 *
 */

code[class^="language-"],
code[class*=" language-"],
pre[class^="language-"],
pre[class*=" language-"] {
    color: #f8f8f2;
    font-family: Consolas, Monaco, 'Andale Mono', monospace;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

/* Code blocks */
pre[class^="language-"],
pre[class*=" language-"] {
    padding: 1em;
    margin: 0 0 15px;
    overflow: auto;
    border-radius: 3px;
    border: none;
}

:not(pre) > code[class^="language-"],
:not(pre) > code[class*=" language-"],
pre[class^="language-"],
pre[class*=" language-"] {
    background: #151515;
}

/* Inline code */
:not(pre) > code[class^="language-"],
:not(pre) > code[class*=" language-"] {
    padding: .1em;
    border-radius: .3em;
}

pre code {
    border: 0;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: slategray;
}

.token.punctuation {
    color: #f8f8f2;
}

.namespace {
    opacity: .7;
}

.token.property,
.token.tag {
    color: #f92672;
}

.token.boolean,
.token.number{
    color: #ae81ff;
}

.token.selector,
.token.attr-name,
.token.string {
    color: #a6e22e;
}


.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
    color: #f8f8f2;
}

.token.atrule,
.token.attr-value
{
    color: #e6db74;
}


.token.keyword{
    color: #66d9ef;
}

.token.regex,
.token.important {
    color: #fd971f;
}

.token.important {
    font-weight: bold;
}

.token.entity {
    cursor: help;
}
pre.line-numbers {
    position: relative;
    padding-left: 3.8em;
    counter-reset: linenumber;
}

pre.line-numbers > code {
    position: relative;
}

.line-numbers .line-numbers-rows {
    position: absolute;
    pointer-events: none;
    top: 0;
    font-size: 100%;
    left: -3.8em;
    width: 3em; /* works for line-numbers below 1000 lines */
    letter-spacing: -1px;
    border-right: 1px solid #999;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

.line-numbers-rows > span {
    pointer-events: none;
    display: block;
    counter-increment: linenumber;
}

.line-numbers-rows > span:before {
    content: counter(linenumber);
    color: #999;
    display: block;
    padding-right: 0.8em;
    text-align: right;
}